* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Josefin Sans', sans-serif;
    position: relative;
    text-align: center;

}

.tasks-container {
    width: 600px;
    height: 500px;
    margin: 1rem auto;
    padding: 2rem;
    border: #c2711a 2px solid;
    border-radius: 20px;
    overflow-y: scroll;


}

@media screen and (max-width: 600px) {
    .tasks-container {
        width: 400px;
    }
}