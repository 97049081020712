.add-form form {
  width: 400px;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 1rem;
}

.input-group {
    /* margin: 0rem .5rem; */
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #c2711a;
  color: #fff;
  font-size: 1rem;

}

button {
    outline: none;
    border: none;
}

.add-btn {
  font-size: 2rem;
  color: #c2711a;
}
::placeholder {
    color: #fff;
    opacity: 1;
}