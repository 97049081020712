.task-card {
  padding: 1rem 3rem 1rem 1rem;
  margin: 1rem 0rem;
  height: 50px;
  background-color: #c2711a;
  color: #fff;
  font-size: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 20px;
}

.task-card.checked {
  background-color: #fff;
  border: #c2711a solid 2px;
  color: #c2711a;
}

.task-title {
  margin-left: 2rem;
  /* text-decoration: line-through */
}

input[type="checkbox"] {
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  accent-color: #c2711a;
  background-color: #c2711a !important;
  border: #fff solid 2px;

  position: absolute;
  top: 25%;
}

.delete-note {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-30%, -50%) !important;
  font-size: 30px;
}

.task-time {
  align-self: flex-end;
}

.task-time input {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  font-size: 1rem;
}

@media screen and (max-width: 600) {
  .task-card {
    background-color: aqua !important;
  }
}
